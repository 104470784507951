// @ts-strict-ignore
import 'lazysizes'
// eslint-disable-next-line import/extensions
import 'lazysizes/plugins/attrchange/ls.attrchange'
import './critical/experiment-tracking'
import './critical/click-tracking'
import './critical/view-tracking'
import './css/main-money.css'
import './critical/provider-logo'
import 'lite-youtube-embed/src/lite-yt-embed.css'
import 'lite-youtube-embed/src/lite-yt-embed.js'
import './critical/calculator'
import './critical/carousel'
import './critical/sticky-nav'
import './critical/accordion'
import './critical/tab'
import carousel from './critical/eevee-carousel'
import './critical/sticky-banner'
import './critical/tabs-selector'
import './critical/travel-money-input'
import './critical/button-atom'
import './critical/platinum-tooltip'
import './critical/email-capture'
import './critical/sticky-top-nav'
import './critical/top-picks'
import './critical/chart'
import './critical/triage'
import './critical/loan-repayment-calculator'
import './critical/superscript'
import './critical/pill'
import './critical/click-tracking-v2'
carousel()
